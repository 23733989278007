import $ from "jquery";
globalThis.jQuery = $;

import './styles.scss';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

import Rellax from 'rellax';

// SLICK SLIDER
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

(function ($) {

    $(function () {

        'use strict';

        // DOM ready, take it away

        // MOBILE MENU submenu
        $('<div class="subarrow"><i class="fa-regular fa-chevron-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
        $('.mobile-menu .subarrow').on('click', function () {
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
            } else {
                $(this).parent().addClass('active');
            }
        });


        // RELLAX
        // ===============================================
        var rellax = new Rellax('.rellax');


        // MOBILE TOGGLE
        // ===============================================

        $('.hamburger').on('click', function () {
            if ($('.mobile-nav').hasClass('active')) {
                $('.mobile-nav, .page-overlay, .hamburger').removeClass('active');
                $('body').removeClass('overflow-hidden');
            } else {
                $('.mobile-nav, .page-overlay').addClass('active');
                $('body').addClass('overflow-hidden');
            }
        });
        $('.page-overlay').on('click', function () {
            $('.mobile-nav, .page-overlay, .hamburger').removeClass('active');
            $('body').removeClass('overflow-hidden');
        });

        $('.mobile-nav ul li a, .header .logo').on('click', function () {
            $('body').removeClass('overflow-hidden');
        });

        // Close mobile menu on click
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
                $('.mobile-menu').removeClass('active');
                $('body').removeClass('overflow-hidden');
            }

        });


        // HEADER SCROLLED
        // ===============================================
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 150) {
                $("header, body").addClass("scrolled");
            }

            if (scroll < 50) {
                $("header, body").removeClass("scrolled");
            }
        });


        AOS.init({
            duration: 400,
            offset: 0, // offset (in px) from the original trigger point
            once: true, // whether animation should happen only once - while scrolling down
            anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
        });
        window.addEventListener('load', AOS.refresh);


        // SLIDER
        // ===============================================
        $('.sliderWrap').slick({
            infinite: true,
            fade: true,
            prevArrow: $('.testnavleft'),
            nextArrow: $('.testnavright'),
            adaptiveHeight: true
        });

    });

})(jQuery);